import * as React from "react"
import {graphql } from "gatsby"


import Navbar from "../components/navbar"
import Reformfooter from "../components/footer"
import Bookaclass from "../components/bookaclass"
import Internalbanner from "../components/internalbanner"
import BookNowContent from "../components/booknowcontent"
import Findus from "../homepage/findus"
import Homeswiper from "../homepage/homeswiper"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Yogaback from "../images/yoga2.jpg";
import Newtimetable from "../components/newtimetable"
import Bsportpasses from "../components/bsportpasses"

const BookNow = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Book Now`


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Book Pilates Classes in Cork Now" description="Book your classes at Reform Pilates Cork"/>
     <Navbar />
    <Internalbanner title="Book Now" subtitle="Reformer Pilates classes in Cork" bannerpic={Yogaback}/>
     <BookNowContent />
               <Newtimetable/>
          <Bsportpasses/>
      <Bookaclass />
     <Findus />
     <Homeswiper />
     <Reformfooter />
    </Layout>
  )
}

export default BookNow

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
