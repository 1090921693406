import * as React from "react"
import styled from "styled-components";
import { Link } from "gatsby"
const Sectionaholder = styled.div`

`;

const BookNowContent = () => {
  return (
  <Sectionaholder>
<section className="section-ab">
	<div className="container "> 
		<div className="columns is-marginless is-centered">
			<div className="column is-8"> 
				<p className="signature">Book a Class</p>
<p className="genpee maintext">We have a range of suitable classes to choose from at Reform Pilates Cork, including our innovative Barre Burn. These classes can be booked online by logging in below and making your selection.
<br/>
<br/>
If you’re a Beginner, we run a dedicated five-week introductory course to the principles and core exercises involved in Reformer Pilates. To sign up, <Link to="/contact">please get in touch today</Link>.</p>
			</div>
		</div>
	</div>
</section>
    </Sectionaholder>
  );
}

export default BookNowContent